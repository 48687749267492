import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import { withPrefix } from "gatsby"

const OrderOnline = () => {
  return (
    <Layout>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Order Online" bgImageClass="order-online-bg" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="" id="Mobi2Go-Storefront"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default OrderOnline
